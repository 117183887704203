import { IProgram, IProgramEntryCodeBatchObject } from 'src/models/program'
import { useBatchEntryCodeForm } from 'src/shared/forms/BatchEntryCode'
import { useCreateBatchEntryCode } from 'src/shared/hooks/program'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import Modal from 'src/shared/views/Modal'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px;
`

interface ICreateBatchEntryCodeModal {
  program: IProgram
  existingBatches: IProgramEntryCodeBatchObject[]
  isOpen: boolean
  handleClose: () => void
}

const CreateBatchEntryCodeModal = ({
  program,
  isOpen,
  handleClose
}: ICreateBatchEntryCodeModal) => {
  /**
   * ----- Hook Initialization -----
   */

  const { FormComponents } = useBatchEntryCodeForm()

  const { createBatchEntryCode, isLoading } = useCreateBatchEntryCode(
    program.id,
    {
      onSuccess: () => handleClose()
    }
  )

  /**
   * --- Variables ---
   */

  // const batchName = watch('batchName')

  // const existingBatch = React.useMemo(
  //   () => existingBatches.find((batch) => batch.batchName === batchName),
  //   [batchName, existingBatches]
  // )

  /**
   * --- Lifecycle ---
   */

  // React.useEffect(() => {
  //   if (existingBatch) {
  //     console.log('existingBatch', existingBatch)
  //     setValue('singleUse', existingBatch.singleUse)
  //   }
  // }, [existingBatch, setValue])

  /**
   * ----- Render -----
   */

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Create Batch Entry Code"
    >
      <FormComponents.Form submitHandler={(data) => createBatchEntryCode(data)}>
        <div>
          <Row>
            <FormComponents.BatchName />
            <FormComponents.AppendSize />
          </Row>
          <FormComponents.SingleUse />
        </div>
        <ButtonContainer>
          <div></div>
          <div>
            <ButtonWithWidth variant="outlined" onClick={handleClose}>
              Cancel
            </ButtonWithWidth>
            <ButtonWithWidth type="submit" isLoading={isLoading}>
              Submit
            </ButtonWithWidth>
          </div>
        </ButtonContainer>
      </FormComponents.Form>
    </Modal>
  )
}

export default CreateBatchEntryCodeModal
