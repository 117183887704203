import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IModuleEssential } from 'src/models/module'
import Item from './Item'

interface IModuleItemProps {
  module: IModuleEssential
  engagementIndex?: number
  disabled?: boolean
}

const ModuleItem = ({
  module,
  engagementIndex,
  disabled
}: IModuleItemProps) => {
  /**
   * ----- Hook Initialization -----
   */

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: module.moduleId,
    data: { currentLocation: engagementIndex, module },
    disabled
  })

  /**
   * ----- Variables -----
   */

  const style = {
    transform: CSS.Translate.toString(transform),
    backgroundColor: disabled ? '#f0f0f0' : 'white'
  }

  /**
   * ----- Render -----
   */
  return (
    <Item
      title={module.title}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    />
  )
}

export default ModuleItem
