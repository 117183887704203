import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { IProgramBatchEntryCodeData } from 'src/models/program'
import * as yup from 'yup'
import TextField, { TextFieldProps } from 'src/shared/views/TextField'
import styled from 'styled-components'
import {
  FormControlLabel,
  Switch,
  SwitchProps,
  Typography
} from '@material-ui/core'
import { FONTS } from '../theme'
import { ErrorText } from '../styled/Text'

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-self: flex-start;
`

const StyledTypography = styled(Typography)`
  font-family: ${FONTS.main};
  color: ${(props) => props.theme.palette.common.black};
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 19px;
`

const BatchEntryCodeSchema = yup.object().shape({
  batchName: yup.string(),
  singleUse: yup.boolean().required(),
  appendSize: yup.number().min(1).max(100).required()
})

export const useBatchEntryCodeForm = (options?: any) => {
  const { handleSubmit, control, ...form } = useForm({
    resolver: yupResolver(BatchEntryCodeSchema),
    defaultValues: {
      batchName: '',
      singleUse: true,
      appendSize: 10,
      ...options?.defaultValues
    },
    ...options
  })

  const FormComponents = {
    Form: ({
      children,
      submitHandler
    }: {
      children: React.ReactNode
      submitHandler: SubmitHandler<IProgramBatchEntryCodeData>
    }) => <form onSubmit={handleSubmit(submitHandler)}>{children}</form>,
    BatchName: ({ ...props }: TextFieldProps) =>
      React.useMemo(
        () => (
          <Controller
            control={control}
            name="batchName"
            render={({ field }) => (
              <TextField {...props} {...field} label="Batch Name" />
            )}
          />
        ),
        [props]
      ),
    SingleUse: ({ ...props }: SwitchProps) =>
      React.useMemo(
        () => (
          <Controller
            control={control}
            name="singleUse"
            render={({ field: { onChange, value, ...field } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...field}
                    {...props}
                  />
                }
                label={<StyledTypography>Single Use</StyledTypography>}
              />
            )}
          />
        ),
        [props]
      ),
    AppendSize: ({ ...props }: TextFieldProps) =>
      React.useMemo(
        () => (
          <Controller
            control={control}
            name="appendSize"
            render={({ field, fieldState }) => (
              <StyledInputContainer>
                <TextField
                  {...props}
                  {...field}
                  label="Batch Size"
                  type="number"
                  error={!!fieldState.error}
                />
                <ErrorText>{fieldState.error?.message}</ErrorText>
              </StyledInputContainer>
            )}
          />
        ),
        [props]
      )
  }

  return { FormComponents, ...form }
}
