import { TableRow } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import React from 'react'
import { Link } from 'react-router-dom'
import { IProgram, IWaitList } from 'src/models/program'
import {
  useAdmitUserToProgram,
  useDenyUserFromProgram
} from 'src/shared/hooks/program'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import {
  FocusedTableCell,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import { fuzzyFilter } from 'src/utils/table'
import styled from 'styled-components'
import DebouncedInput from './DebounceInput'

const ActionRow = styled.div`
  display: flex;
  justify-content: space-around;
`

interface IWaitListTable {
  program: IProgram
  waitList: IWaitList[]
}

const WaitListTable = ({ waitList, program }: IWaitListTable) => {
  /**
   * ----- Hook Initialization -----
   */

  const { admitUser, isLoading: allowLoading } = useAdmitUserToProgram(
    program.id
  )

  const { denyUser, isLoading: denyLoading } = useDenyUserFromProgram(
    program.id
  )

  const isLoading = allowLoading || denyLoading

  /**
   * ----- Variables -----
   */

  const columns = React.useMemo<ColumnDef<IWaitList, any>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'userName',
        cell: ({ row }) => (
          <Link
            to={`/users/${row.original.userId}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {row.original.userName}
          </Link>
        )
      },
      {
        header: 'DINs',
        accessorFn: (row) => {
          return row.prescriptions.map(
            (prescription) =>
              `${prescription.medicationName} (${prescription.din})`
          )
        },
        cell: ({ renderValue }) => renderValue().join(', '),
        filterFn: fuzzyFilter
      },
      {
        header: 'Diagnosed',
        accessorKey: 'diagnosedByProfessional',
        accessorFn: (row) => row.diagnosedByProfessional?.toString(),
        cell: ({
          row: {
            original: { diagnosedByProfessional }
          }
        }) => {
          if (diagnosedByProfessional === true) {
            return <Check style={{ color: 'green' }} />
          } else if (diagnosedByProfessional === false) {
            return <Close style={{ color: 'red' }} />
          } else {
            return 'Unknown'
          }
        }
      },
      {
        header: 'Pre-Screening',
        accessorKey: 'screeningAnswers',
        cell: ({
          row: {
            original: { screeningAnswers }
          }
        }) => {
          return (
            <div>
              {screeningAnswers?.answers.map((answer) => {
                return (
                  <p key={answer.name}>
                    <strong>{answer.name}</strong>:{' '}
                    {answer.answer ? (
                      <Check style={{ color: 'green' }} />
                    ) : (
                      <Close style={{ color: 'red' }} />
                    )}
                  </p>
                )
              })}
            </div>
          )
        }
      },
      {
        id: 'actions',
        cell: ({ row }) => {
          return (
            <ActionRow>
              <ButtonWithMargin
                backgroundcolor="red"
                isLoading={isLoading}
                onClick={() => {
                  denyUser(row.original.userId)
                }}
              >
                Deny Entry
              </ButtonWithMargin>
              <ButtonWithMargin
                isLoading={isLoading}
                onClick={() => admitUser(row.original.userId)}
              >
                Allow Entry
              </ButtonWithMargin>
            </ActionRow>
          )
        }
      }
    ],
    [admitUser, denyUser, isLoading]
  )

  /**
   * ----- Render -----
   */

  const table = useReactTable({
    columns,
    data: waitList,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <StyledTable>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell
                key={header.id}
                style={{ width: header.getSize() }}
              >
                <>
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                  {header.column.getCanFilter() ? (
                    <DebouncedInput
                      type="text"
                      value={(header.column.getFilterValue() ?? '') as string}
                      onChange={(value) => header.column.setFilterValue(value)}
                      placeholder={`Search...`}
                      list={header.column.id + 'list'}
                    />
                  ) : null}
                </>
              </TableHeaderCell>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <FocusedTableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </FocusedTableCell>
                )
              })}
            </TableRow>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

export default WaitListTable
