import React from 'react'
import { toast } from 'react-toastify'
import { LanguageMap } from 'src/models/misc'
import { IProgram, IProgramConsent } from 'src/models/program'
import { useUpdateProgramConsent } from 'src/shared/hooks/program'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import {
  DetailsContainer,
  DetailsHeader,
  DetailsHeaderContainer
} from 'src/shared/styled/Layout'
import { Container } from 'src/shared/styled/Module'
import { HeaderName, SubHeaderName } from 'src/shared/styled/Text'
import LanguageMapForm from 'src/shared/views/LanguageMapForm'
import styled from 'styled-components'

const ContentContainer = styled.div`
  width: 100%;
`

interface IParams {
  consent?: IProgramConsent
  program: IProgram
}

const ProgramConsent = ({ consent, program }: IParams) => {
  const initialConsentContent: LanguageMap =
    consent?.content ||
    program.supportedLanguages
      .map((language) => ({
        [language]: ''
      }))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {})

  /**
   * --- Hook Initialization ---
   */

  const [consentContent, setConsentContent] = React.useState(
    initialConsentContent
  )

  const { isLoading, updateConsent } = useUpdateProgramConsent(program.id, {
    onSuccess: () => {
      toast.success('Consent updated')
    },
    onError: () => {
      toast.error(
        'Failed to update consent, try refreshing the page and trying again'
      )
    }
  })

  /**
   * --- Functions ---
   */

  const handleSubmit = () => {
    updateConsent({
      content: consentContent,
      version: consent?.version !== undefined ? consent.version : 0
    })
  }

  /**
   * --- Render ---
   */

  return (
    <DetailsContainer>
      <DetailsHeaderContainer>
        <DetailsHeader>
          <div>
            <HeaderName>{program.name}</HeaderName>
            <SubHeaderName>
              Consent Form{' '}
              {consent?.version !== undefined ? `v${consent.version}` : null}
            </SubHeaderName>
          </div>
          <div>
            <ButtonWithWidth
              isLoading={isLoading}
              onClick={() => setConsentContent(initialConsentContent)}
              disabled={consentContent === initialConsentContent}
            >
              CANCEL
            </ButtonWithWidth>
            <ButtonWithWidth
              isLoading={isLoading}
              onClick={handleSubmit}
              disabled={consentContent === initialConsentContent}
            >
              SAVE
            </ButtonWithWidth>
          </div>
        </DetailsHeader>
      </DetailsHeaderContainer>
      <Container>
        <ContentContainer>
          <LanguageMapForm
            supportedLanguages={program.supportedLanguages}
            value={consentContent}
            onChange={(value) => setConsentContent(value)}
            textFieldProps={{
              multiline: true
            }}
          />
        </ContentContainer>
      </Container>
    </DetailsContainer>
  )
}

export default ProgramConsent
