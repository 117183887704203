import { FormLabel } from '@material-ui/core'
import React from 'react'
import { LanguageMap } from 'src/models/misc'
import styled from 'styled-components'
import TextField, { TextFieldProps } from './TextField'

const FormControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`

const StyledTextField = styled(TextField)`
  margin: 10px 0;
  width: 100%;
`

interface ILanguageMap {
  value: LanguageMap
  onChange: (value: LanguageMap) => void
  label?: string
  supportedLanguages: ('en' | 'fr')[]
  textFieldProps?: Omit<TextFieldProps, 'ref'>
}

const LanguageMapForm = ({
  value,
  onChange,
  label,
  supportedLanguages,
  textFieldProps
}: ILanguageMap) => {
  /**
   * --- Hook Initialization ---
   */

  // Create a ref object to store cursor positions for each language field
  const cursorPositionRef = React.useRef<Record<string, number>>({})
  // Create refs for each text field
  const textFieldRefs = React.useRef<Record<string, HTMLInputElement | null>>(
    {}
  )

  /**
   * ----- Functions -----
   */

  const getTextFieldLabel = React.useCallback((lang: string) => {
    switch (lang) {
      case 'en':
        return 'English'
      case 'fr':
        return 'French'
      default:
        return lang
    }
  }, [])

  const handleTextFieldChange = React.useCallback(
    (
      lang: string,
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      // Store the current cursor position before the update
      cursorPositionRef.current[lang] = e.target.selectionStart || 0

      // Update the value through the parent component
      onChange({ ...value, [lang]: e.target.value })
    },
    [onChange, value]
  )

  /**
   * ----- Lifecycle -----
   */

  // Restore cursor position after render
  React.useEffect(() => {
    supportedLanguages.forEach((lang) => {
      const inputElement = textFieldRefs.current[lang]
      const cursorPosition = cursorPositionRef.current[lang]

      if (inputElement && cursorPosition !== undefined) {
        inputElement.setSelectionRange(cursorPosition, cursorPosition)
      }
    })
  })

  /**
   * ----- Render -----
   */

  return (
    <FormControlContainer>
      {label && <FormLabel>{label}</FormLabel>}
      {supportedLanguages.map((lang) => (
        <StyledTextField
          key={lang}
          label={getTextFieldLabel(lang)}
          value={value[lang]}
          onChange={(e) => handleTextFieldChange(lang, e)}
          inputRef={(el) => {
            textFieldRefs.current[lang] = el
          }}
          {...textFieldProps}
        />
      ))}
    </FormControlContainer>
  )
}

export default LanguageMapForm
