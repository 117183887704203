import { useParams } from 'react-router-dom'
import { useGetProgramConsent, useProgram } from 'src/shared/hooks/program'

import LoadingScreen from 'src/shared/views/LoadingScreen'
import ProgramConsent from '../views/Consent'

interface IParams {
  id: string
}

const ProgramConsentContainer = () => {
  /**
   * ----- Hook Initialization -----
   */

  const { id } = useParams<IParams>()

  const { isLoading: programLoading, program } = useProgram(id)

  const { consent, isLoading } = useGetProgramConsent(id)

  /**
   * ----- Render -----
   */

  if (!isLoading && !programLoading && program)
    return <ProgramConsent program={program} consent={consent} />

  return <LoadingScreen />
}

export default ProgramConsentContainer
