import React, { createContext } from 'react'
import { useImmerReducer } from 'use-immer'

import { IProgram } from '../../models/program'
import {
  CreateProgramAction,
  createProgramReducer
} from '../../reducers/CreateProgram'

const initialState: IProgram = {
  id: '',
  name: '',
  commonName: '',
  commonNameTranslations: {},
  coveredDins: [],
  entryPoint: {
    id: '',
    description: '',
    displayLabel: '',
    imageUrl: '',
    promptForCode: false,
    visibleToPatient: false,
    consentEnabled: false
  },
  endDate: new Date(),
  supportedLanguages: [],
  funds: {
    amount: 0,
    currencyCode: 'CAD'
  },
  waitListEnabled: false
}

const StateContext = createContext<IProgram>(initialState)
const DispatchContext = createContext<React.Dispatch<CreateProgramAction>>(
  () => null
)

const ProgramProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useImmerReducer(createProgramReducer, initialState)

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export { StateContext, DispatchContext, ProgramProvider }
