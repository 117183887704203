import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@material-ui/core'
import { SupportedLanguages } from 'src/models/misc'

interface ISupportedLanguageForm {
  value: SupportedLanguages
  onChange: (value: SupportedLanguages) => void
  englishDisabled?: boolean
}

const SupportedLanguageForm = ({
  value,
  onChange,
  englishDisabled
}: ISupportedLanguageForm) => {
  /**
   * ----- Functions -----
   */

  const updateSupportedLanguages = (lang: 'en' | 'fr', checked: boolean) => {
    if (checked) {
      onChange([...value, lang])
    } else {
      onChange(value.filter((l) => l !== lang))
    }
  }

  /**
   * ----- Render -----
   */
  return (
    <FormControl component="fieldset" style={{ margin: '15px' }}>
      <FormLabel component="legend">Supported Languages</FormLabel>
      <FormGroup>
        <FormControlLabel
          label="English"
          control={
            <Checkbox
              disabled={englishDisabled || false}
              checked={value.includes('en')}
              onChange={(e) => updateSupportedLanguages('en', e.target.checked)}
            />
          }
        />
        <FormControlLabel
          label="French"
          control={
            <Checkbox
              checked={value.includes('fr')}
              onChange={(e) => updateSupportedLanguages('fr', e.target.checked)}
            />
          }
        />
      </FormGroup>
    </FormControl>
  )
}

export default SupportedLanguageForm
