/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { UUID } from 'src/models/misc'
import { IModule, ModuleTask } from 'src/models/module'
import { IProgram } from 'src/models/program'
import {
  DetailsContainer,
  DetailsHeader,
  DetailsHeaderContainer
} from 'src/shared/styled/Layout'
import { HeaderName, SubHeaderName } from 'src/shared/styled/Text'
import styled from 'styled-components'

const convertToYaml = (obj: any, depth = 0): string => {
  const indent = '  '.repeat(depth)

  if (typeof obj !== 'object' || obj === null) {
    // Handle primitive values
    if (typeof obj === 'string') {
      if (obj.includes('\n')) {
        return (
          '|-\n' +
          obj
            .split('\n')
            .map((line) => indent + '  ' + line)
            .join('\n')
        )
      }
      // Check if string needs quotes
      return obj.match(/[:#{}[\],&*?|<>=!%@`]/) ? `'${obj}'` : obj
    }
    return String(obj)
  }

  // prettier-ignore
  if (Array.isArray(obj)) {
    return obj.length === 0
      ? '[]'
      : '\n' +
      obj
        .map(
          (item) =>
            indent +
            '- ' +
            convertToYaml(item, depth + 1).replace(/^\s*/, '')
        )
        .join('\n')
  }

  const result = Object.entries(obj)
    .map(([key, value]) => {
      const valueStr = convertToYaml(value, depth + 1)
      if (valueStr.startsWith('\n')) {
        return `${indent}${key}:${valueStr}`
      }
      return valueStr === '{}'
        ? `${indent}${key}: {}`
        : `${indent}${key}: ${valueStr}`
    })
    .join('\n')

  return depth === 0 ? result : '\n' + result
}

const Container = styled.div`
  padding: 20px;
`

const CodeBlockContainer = styled.div`
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  font-family: 'Consolas', 'Monaco', 'Courier New', monospace;
  color: #d4d4d4;
  position: relative;
`

const CodeHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
`

const CodeContent = styled.pre`
  margin: 0;
  overflow-x: auto;
  line-height: 1.5;

  .keyword {
    color: #569cd6;
  }
  .string {
    color: #ce9178;
  }
  .comment {
    color: #6a9955;
  }
  .function {
    color: #dcdcaa;
  }
  .number {
    color: #b5cea8;
  }
`

interface IProgramOmni {
  program: IProgram
  modules: IModule[]
}

const ProgramOmni = ({ program, modules }: IProgramOmni) => {
  /**
   * --- Variables ---
   */

  const configObject = React.useMemo(() => {
    const sqlPart = `WITH questions_and_answers AS (
        SELECT
          task_id AS task_id
          , user_id AS user_id
          , selection_text AS chosen_answers
          , ROW_NUMBER() OVER (PARTITION BY user_id, task_id ORDER BY selection_text) AS answer_rank
        FROM completed_module_tasks AS questions
      ),`

    // Collect all tasks from all modules
    const allTasks: Record<UUID, ModuleTask> = modules.reduce((acc, module) => {
      return { ...acc, ...module.tasks }
    }, {})

    const taskToModuleMap = modules.reduce((map, module) => {
      Object.keys(module.tasks).forEach((taskId) => {
        map[taskId] =
          module.title.en || module.title.fr || 'Could not find language'
      })
      return map
    }, {} as Record<string, string>)

    // Generate the pivot part
    const taskIds = Object.keys(allTasks)
    const pivotColumns = taskIds
      .map((taskId) => {
        const sanitizedId = taskId.replace(/-/g, '')
        return `MIN(CASE WHEN task_id = '${taskId}' THEN chosen_answers ELSE NULL END) AS "${sanitizedId}"`
      })
      .join(',\n      ')

    const pivotPart = `
      pivoted_survey AS (
        SELECT
          user_id,
          answer_rank,
          ${pivotColumns}
        FROM questions_and_answers
        GROUP BY user_id, answer_rank
      )`

    // Generate dimensions
    const dimensions: Record<
      string,
      {
        sql?: string
        label?: string
        group_label?: string
        display_order?: number
      }
    > = taskIds.reduce(
      (acc, taskId) => {
        const task = allTasks[taskId]
        const sanitizedId = taskId.replace(/-/g, '')
        const moduleTitle = taskToModuleMap[taskId]

        return {
          ...acc,
          [sanitizedId]: {
            label: task.text.en,
            group_label: moduleTitle // Now using the module title instead of program name
          }
        }
      },
      {
        answer_rank: {},
        user_id: {
          sql: '"user_id"',
          display_order: 1
        }
      }
    )

    return {
      label: program.name,
      sql: `${sqlPart}${pivotPart}\n  SELECT * FROM pivoted_survey\n  ORDER BY user_id, answer_rank`,
      dimensions,
      measures: {
        number_of_patients: {
          // eslint-disable-next-line no-template-curly-in-string
          sql: '${user_id}',
          drill_fields: [
            'users.age_bins',
            'users.province',
            'users.gender',
            'number_of_patients'
          ],
          aggregate_type: 'count_distinct'
        }
      }
    }
  }, [modules, program.name])

  const yamlConfig = React.useMemo(() => {
    return convertToYaml(configObject)
  }, [configObject])

  /**
   * --- Render ---
   */

  return (
    <DetailsContainer>
      <DetailsHeaderContainer>
        <DetailsHeader>
          <div>
            <HeaderName>{program.name}</HeaderName>
            <SubHeaderName>Omni</SubHeaderName>
          </div>
        </DetailsHeader>
      </DetailsHeaderContainer>
      <Container>
        <h3>Generated View</h3>
        <CodeBlockContainer>
          <CodeHeader>{`${program.name
            .toLowerCase()
            .replace(/\s+/g, '_')}.query.view`}</CodeHeader>
          <CodeContent>{yamlConfig}</CodeContent>
        </CodeBlockContainer>
      </Container>
    </DetailsContainer>
  )
}

export default ProgramOmni
