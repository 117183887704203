import React from 'react'

import { Text, Layout } from 'src/shared/styled'
import { IProgram } from 'src/models/program'
import { IEngagement } from 'src/models/module'
import styled from 'styled-components'
import {
  useGetModules,
  useSyncEngagementsForProgram,
  useUpdateEngagementsForProgram
} from 'src/shared/hooks/modules'
import EngagementList from 'src/components/Engagement/EngagementList'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent
} from '@dnd-kit/core'
import AllModulesList from 'src/components/Engagement/AllModulesList'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import Item from 'src/components/Engagement/Item'
import { toast } from 'react-toastify'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters
} from 'react-query'

const Container = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 10px;
  max-width: 100%;
  max-height: 78vh;
  border: 1px solid #e0e0e0;
`

const ContentContainer = styled.div`
  min-width: 0;
  height: 100%;
  overflow-y: auto;
`

const AddEngagement = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
`

const ErrorContainer = styled.div`
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 10px;
  color: red;
`

const ErrorText = styled.p`
  margin: 0;
`

interface IEngagementsProps {
  engagements: IEngagement[]
  version?: number
  program: IProgram
  refetchEngagements: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<
    QueryObserverResult<
      {
        version?: number | undefined
        engagements: IEngagement[]
      },
      unknown
    >
  >
}

const { DetailsContainer, DetailsHeader, DetailsHeaderContainer } = Layout
const { HeaderName, SubHeaderName } = Text

const Engagements: React.FC<IEngagementsProps> = ({
  program,
  engagements,
  version,
  refetchEngagements
}) => {
  const { name: programName } = program

  /**
   * ----- Hook Initialization -----
   */

  const [syncInProgress, setSyncInProgress] = React.useState(false)

  const [engagementState, setEngagementState] =
    React.useState<IEngagement[]>(engagements)

  const [activeTitle, setActiveTitle] = React.useState<string | null>(null)

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  const { modules: allModules } = useGetModules()

  const { isLoading, updateEngagementsForProgram } =
    useUpdateEngagementsForProgram(program.id, {
      onSuccess: () => {
        toast.success('Engagements updated successfully')
      },
      onError: (error) => {
        toast.error(`Error updating engagements: ${error.message}`)
      }
    })

  const { syncEngagementsForProgram, isLoading: syncProgramLoading } =
    useSyncEngagementsForProgram(program.id, {
      onSuccess: () => {
        toast.success('Engagements sync proecess started')
      },
      onError: (error) => {
        toast.error(`Error commencing engagements sync: ${error.message}`)
      }
    })

  /**
   * ----- Variables -----
   */

  const engagementStats = React.useMemo(() => {
    return engagements.map((engagement) => {
      return engagement.assignedEngagementStats
    })
  }, [engagements])

  const filteredModules = React.useMemo(() => {
    return allModules?.filter((module) => {
      return !engagementState.some((engagement) => {
        return engagement.modules.some(
          (engagementModule) => engagementModule.moduleId === module.moduleId
        )
      })
    })
  }, [allModules, engagementState])

  const syncDisabled = React.useMemo(() => {
    const syncRequired = engagementStats.some(
      (stat) => stat?.synced !== stat?.locked
    )
    return !syncRequired || syncInProgress || version === undefined
  }, [engagementStats, syncInProgress, version])

  /**
   * ----- Functions -----
   */

  const handleDragEnd = (event: DragEndEvent) => {
    const { over, active } = event
    if (!over) return
    if (!active) return

    const engagementStateCopy = [...engagementState]

    const module = active.data.current?.module
    if (!module) return

    const removeFromEngagement = () => {
      // Remove the module from the previous location
      const currentLocation = active.data.current?.currentLocation as number
      if (
        currentLocation !== undefined &&
        engagementStateCopy[currentLocation]
      ) {
        // Remove the module from the previous location
        engagementState[currentLocation].modules = engagementStateCopy[
          currentLocation
        ].modules.filter((module) => module.moduleId !== active.id)
      }
    }

    if (engagementState[over.id as number]) {
      // If the module is being dragged to an engagement

      if (
        !engagementState[over.id as number].assignedEngagementStats?.unlocked
      ) {
        removeFromEngagement()

        // Add the module to the new location
        engagementStateCopy[over.id as number].modules.push(module)
      }
    } else if (over.id === 'removal') {
      // Module being dragged to the removal area
      removeFromEngagement()
    } else if (over.data.current?.module) {
      // Module is being sorted
      const { currentLocation } = over.data.current
      if (currentLocation !== undefined) {
        // Find the index of the module being sorted
        const index = engagementStateCopy[currentLocation].modules.findIndex(
          (module) => module.moduleId === active.id
        )

        // Add the module to the new location
        if (index !== -1) {
          // Find the index of the module it is being sorted before
          const newIndex = engagementStateCopy[
            currentLocation
          ].modules.findIndex((module) => module.moduleId === over.id)

          // Remove the module from the previous location
          engagementStateCopy[currentLocation].modules.splice(index, 1)

          // Add the module to the new location
          engagementStateCopy[currentLocation].modules.splice(
            newIndex,
            0,
            module
          )
        } else {
          engagementStateCopy[currentLocation].modules.push(module)
        }
      } else {
        // Module is being dragged to the removal area
        removeFromEngagement()
      }
    }

    setEngagementState(engagementStateCopy)
  }

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event

    setActiveTitle(active.data.current?.module?.title || null)
  }

  const handleSubmit = () => {
    // Check if all engagements have at least one module
    if (engagementState.some((engagement) => engagement.modules.length === 0)) {
      setErrorMessage('All engagements must have at least one module')
      return
    }

    // Check if all engagement days are positive and above 0
    if (
      engagementState.some((engagement) => (engagement.daysUntilNext || 0) < 0)
    ) {
      setErrorMessage('Engagement days must be positive')
      return
    }

    updateEngagementsForProgram({
      engagements: engagementState.map((engagement) => {
        return {
          ...engagement,
          modules: engagement.modules.map((module) => module.moduleId)
        }
      }),
      version: version || 0
    })
  }

  const handleSyncClick = () => {
    setSyncInProgress(true)
    syncEngagementsForProgram({ version: version || 0 })
  }

  /**
   * --- Lifecycle ---
   */

  const useBackoffRefetch = (
    condition: boolean,
    callback: () => void,
    options = {
      initialDelay: 1000,
      maxDelay: 10000,
      maxDuration: 120000, // 2 minutes
      backoffFactor: 1.5
    }
  ) => {
    React.useEffect(() => {
      if (!condition) return

      const startTime = Date.now()
      let currentDelay = options.initialDelay
      let timeoutId: NodeJS.Timeout

      const scheduleNextRefetch = () => {
        const elapsedTime = Date.now() - startTime

        // Stop if we've exceeded maxDuration
        if (elapsedTime >= options.maxDuration) {
          toast.error('Sync timed out')
          return
        }

        timeoutId = setTimeout(() => {
          callback()

          // Calculate next delay with exponential backoff
          currentDelay = Math.min(
            currentDelay * options.backoffFactor,
            options.maxDelay
          )

          scheduleNextRefetch()
        }, currentDelay)
      }

      scheduleNextRefetch()

      return () => {
        if (timeoutId) clearTimeout(timeoutId)
      }
    }, [condition, callback, options])
  }

  React.useEffect(() => {
    if (!syncInProgress) return

    // Check if sync is complete
    if (!engagementStats.some((stat) => stat?.synced !== stat?.locked)) {
      setSyncInProgress(false)
      setEngagementState(engagements)
    }
  }, [engagementStats, engagements, syncInProgress])

  // Use the backoff hook for refetching
  useBackoffRefetch(
    syncInProgress &&
      engagementStats.some((stat) => stat?.synced !== stat?.locked),
    () => {
      refetchEngagements()
    },
    {
      initialDelay: 1000, // Start with 1 second
      maxDelay: 10000, // Max delay of 10 seconds between retries
      maxDuration: 120000, // Stop after 2 minutes
      backoffFactor: 1.5 // Increase delay by 50% each time
    }
  )

  /**
   * ----- Render -----
   */

  return (
    <DetailsContainer>
      <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <DetailsHeaderContainer>
          <DetailsHeader>
            <div>
              <HeaderName>{programName}</HeaderName>
              <SubHeaderName>
                Engagements {version ? `v${version}` : null}
              </SubHeaderName>
            </div>
            <div>
              <ButtonWithWidth
                isLoading={syncProgramLoading}
                onClick={() => handleSyncClick()}
                disabled={syncDisabled}
              >
                SYNC
              </ButtonWithWidth>
              <ButtonWithWidth
                isLoading={isLoading}
                onClick={handleSubmit}
                disabled={engagements === engagementState}
              >
                SAVE
              </ButtonWithWidth>
            </div>
          </DetailsHeader>
        </DetailsHeaderContainer>
        <ErrorContainer>
          {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
        </ErrorContainer>
        <Container>
          <ContentContainer>
            {engagementState.map((engagement, index) => (
              <EngagementList
                key={index}
                engagement={engagement}
                index={index}
                isLast={index === engagementState.length - 1}
                onDaysChange={(days) => {
                  const engagementStateCopy = [...engagementState]
                  engagementStateCopy[index].daysUntilNext = days
                  setEngagementState(engagementStateCopy)
                }}
                onDelete={() => {
                  const engagementStateCopy = [...engagementState]
                  engagementStateCopy.splice(index, 1)
                  setEngagementState(engagementStateCopy)
                }}
                onFundsChange={(funds) => {
                  const engagementStateCopy = [...engagementState]
                  engagementStateCopy[index].funds.amount = funds
                  setEngagementState(engagementStateCopy)
                }}
              />
            ))}
            <AddEngagement
              onClick={() => {
                setEngagementState([
                  ...engagementState,
                  {
                    funds: { amount: 0, currencyCode: 'CAD' },
                    daysUntilNext: 0,
                    modules: []
                  }
                ])
              }}
            >
              <h2>Add Engagement</h2>
            </AddEngagement>
          </ContentContainer>
          <ContentContainer>
            {filteredModules ? (
              <AllModulesList modules={filteredModules} />
            ) : (
              <LoadingScreen />
            )}
          </ContentContainer>
        </Container>
        <DragOverlay>
          {activeTitle ? <Item title={activeTitle} /> : null}
        </DragOverlay>
      </DndContext>
    </DetailsContainer>
  )
}

export default Engagements
