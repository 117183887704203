import { MouseEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Delete } from '@material-ui/icons'

import { Text, Layout, Buttons } from '../../../shared/styled'
import ProgramInfo from './ProgramInfo'
import {
  IProgram,
  IProgramEntryCode,
  IProgramUpdateData
} from '../../../models/program'
import WarningNotification from '../../../shared/views/WarningNotification'
import { ProgramMatchParams } from 'src/models/routeParams'
import { usePermissions } from 'src/shared/hooks/usePermissions'
import { UserPermissionsEnum } from 'src/models/user'
import ExitPrompt from 'src/shared/views/ExitPrompt'
import {
  useDeleteProgram,
  useProgramBatchEntryCodes,
  useProgramEntryCodes,
  useUpdateProgram,
  useUpdateProgramEndDate
} from 'src/shared/hooks/program'
import DinUpdate from './DinUpdate'
import TextField from 'src/shared/views/TextField'
import moment from 'moment'
import { ButtonWithWidth } from 'src/shared/styled/Buttons'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import styled from 'styled-components'
import Modal from 'src/shared/views/Modal'
import { COLORS } from 'src/shared/theme'
import ProgramEntryCode from './EntryPoint'
import { useProgramUpdateForm } from '../hooks/forms'
import { toast } from 'react-toastify'
import LanguageMapDisplay from 'src/shared/views/LanguageMap'
import EntryCodeTable from './EntryCodeTable'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import CreateEntryCodeModal from './CreateEntryCode'
import UpdateEntryCodeModal from './UpdateEntryCode'
import CreateBatchEntryCodeModal from './CreateBatchEntryCode'
import EntryCodeBatchesTable from './EntryCodeBatchesTable'
import EntryCodeBatchDetails from './EntryCodeBatchDetails'

const {
  SummaryContainer,
  DetailsHeaderContainer,
  DetailsContainer,
  DetailsHeader
} = Layout
const { ButtonWithMargin } = Buttons
const { Summary, Heading, HeaderName, SubHeaderName } = Text

const TextContainer = styled.div`
  margin-bottom: 2em;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
`

interface IProgramDetails {
  program: IProgram
}

const ProgramDetails = ({ program }: IProgramDetails) => {
  /**
   * ----- Hook Initialization -----
   */

  const { id: programId } = useParams<ProgramMatchParams>()
  const permissions = usePermissions()
  const canCurrentUserWrite = permissions.includes(
    UserPermissionsEnum.ProgramWrite
  )
  const history = useHistory()

  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  const [showBatchDetails, setShowBatchDetails] = useState<string | undefined>(
    undefined
  )
  const [showCreateEntryCodeModal, setShowCreateEntryCodeModal] =
    useState(false)
  const [showUpdateEntryCodeModal, setShowUpdateEntryCodeModal] = useState<
    IProgramEntryCode | undefined
  >(undefined)
  const [showBatchEntryCodeModal, setShowBatchEntryCodeModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [understandCheck, setUnderstandCheck] = useState(false)

  const { control, formState, reset, handleSubmit, watch } =
    useProgramUpdateForm({
      defaultValues: {
        ...program
      }
    })

  const { update: updateProgram } = useUpdateProgram(programId, {
    onSuccess: () => {
      toast.success('Program updated successfully')
      reset()
    },
    onError: (err) => {
      toast.error((err as Error).message)
    }
  })

  const { delete: deleteProgram } = useDeleteProgram(programId, {
    onSuccess: () => history.push('/programs')
  })

  const { updateEndDate, isLoading: endDateUpdateLoading } =
    useUpdateProgramEndDate(programId, {})

  const { entryCodes, isLoading: entryCodesLoading } =
    useProgramEntryCodes(programId)

  const { batchEntryCodes, isLoading: batchEntryCodesLoading } =
    useProgramBatchEntryCodes(programId)

  const supportedLanguages = watch('supportedLanguages')

  /**
   * ----- Functions -----
   */

  const handleUpdateProgram = (data: IProgramUpdateData) => {
    updateProgram(data)
  }

  const handleEndDateUpdate = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    updateEndDate(new Date(endDate))
  }

  /**
   * ----- Lifecycle -----
   */

  useEffect(() => {
    if (program) {
      setEndDate(moment(program.endDate).utc().format('YYYY-MM-DD'))
    }
  }, [program])

  /**
   * ----- Render -----
   */

  return (
    <>
      <DetailsContainer>
        <DetailsHeaderContainer>
          {formState.isDirty && <WarningNotification />}
          <DetailsHeader>
            <div>
              <HeaderName>{program.name.toUpperCase()}</HeaderName>
              <SubHeaderName>Program Details</SubHeaderName>
            </div>
            {canCurrentUserWrite && (
              <div>
                {formState.isDirty && (
                  <>
                    <ButtonWithMargin
                      onClick={() => reset()}
                      variant="outlined"
                    >
                      CANCEL
                    </ButtonWithMargin>
                    <ButtonWithMargin
                      color="default"
                      onClick={handleSubmit(handleUpdateProgram)}
                    >
                      SAVE
                    </ButtonWithMargin>
                  </>
                )}
                <ButtonWithMargin
                  onClick={() => setShowDeleteModal(true)}
                  startIcon={<Delete />}
                >
                  DELETE PROGRAM
                </ButtonWithMargin>
              </div>
            )}
          </DetailsHeader>
        </DetailsHeaderContainer>
        <div style={{ padding: '57px 34px 25px 36px' }}>
          <ProgramInfo
            control={control}
            canCurrentUserWrite={canCurrentUserWrite}
            supportedLanguages={supportedLanguages || []}
          />
          <SummaryContainer>
            <Heading>Program Name</Heading>
            <Summary>{program.name}</Summary>
          </SummaryContainer>
          {program.commonNameTranslations && (
            <SummaryContainer>
              <Heading>Common Name</Heading>
              <LanguageMapDisplay
                value={program.commonNameTranslations}
                supportedLanguages={program.supportedLanguages}
              />
            </SummaryContainer>
          )}
          <DinUpdate program={program} />
          <SummaryContainer>
            <Heading>End Date</Heading>
            <TextField
              id="endDate"
              name="endDate"
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              disabled={!canCurrentUserWrite}
            />
            <ButtonWithWidth
              onClick={handleEndDateUpdate}
              isLoading={endDateUpdateLoading}
            >
              Save
            </ButtonWithWidth>
          </SummaryContainer>
          {program.entryPoint && (
            <>
              <SummaryContainer>
                <Row>
                  <Heading>Entry Codes</Heading>
                  <div>
                    <ButtonWithWidth
                      onClick={() => setShowCreateEntryCodeModal(true)}
                    >
                      Create
                    </ButtonWithWidth>
                  </div>
                </Row>
                <Summary style={{ marginTop: '2em' }}>
                  {entryCodesLoading ? (
                    <LoadingScreen />
                  ) : (
                    <EntryCodeTable
                      entryCodes={entryCodes || []}
                      selectEntryCode={(entryCode) =>
                        setShowUpdateEntryCodeModal(entryCode)
                      }
                    />
                  )}
                </Summary>
              </SummaryContainer>
              <SummaryContainer>
                <Row>
                  <Heading>Entry Code Batches</Heading>
                  <ButtonWithWidth
                    onClick={() => setShowBatchEntryCodeModal(true)}
                  >
                    Create
                  </ButtonWithWidth>
                </Row>
                <Summary style={{ marginTop: '2em' }}>
                  {batchEntryCodesLoading ? (
                    <LoadingScreen />
                  ) : (
                    <EntryCodeBatchesTable
                      batches={batchEntryCodes?.batches || []}
                      selectEntryCodeBatch={(batchName) =>
                        setShowBatchDetails(batchName)
                      }
                    />
                  )}
                </Summary>
              </SummaryContainer>
            </>
          )}
          <SummaryContainer>
            <Heading>Entry Point</Heading>
            <ProgramEntryCode program={program} />
          </SummaryContainer>
        </div>
      </DetailsContainer>
      <ExitPrompt
        shouldPrompt={formState.isDirty}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return true
        }}
      />
      <CreateEntryCodeModal
        isOpen={showCreateEntryCodeModal}
        handleClose={() => setShowCreateEntryCodeModal(false)}
        program={program}
      />
      <CreateBatchEntryCodeModal
        isOpen={showBatchEntryCodeModal}
        handleClose={() => setShowBatchEntryCodeModal(false)}
        program={program}
        existingBatches={batchEntryCodes?.batches || []}
      />
      {showUpdateEntryCodeModal && (
        <UpdateEntryCodeModal
          isOpen={!!showUpdateEntryCodeModal}
          entryCode={showUpdateEntryCodeModal}
          handleClose={() => setShowUpdateEntryCodeModal(undefined)}
          program={program}
        />
      )}
      {showBatchDetails && (
        <EntryCodeBatchDetails
          programId={programId}
          batchName={showBatchDetails}
          handleClose={() => setShowBatchDetails(undefined)}
          isOpen={!!showBatchDetails}
        />
      )}
      <Modal
        isOpen={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        title="Delete Program"
      >
        <div>
          <TextContainer>
            <Typography>
              Please read carefully! This action cannot be undone.
            </Typography>
            <Typography
              style={{
                fontWeight: 'bolder',
                fontSize: '1.25em',
                color: COLORS.warningRed,
                marginTop: '0.5em'
              }}
            >
              Warning: this button deletes a program along with any data
              associated with it, e.g.: any in-progress user learning sessions,
              transactions, etc. Are you absolutely sure you wish to proceed?
              This action cannot be undone.
            </Typography>
            <FormControlLabel
              label="I Understand"
              control={
                <Checkbox
                  checked={understandCheck}
                  onChange={(e) => setUnderstandCheck(e.target.checked)}
                />
              }
            ></FormControlLabel>
          </TextContainer>
          <ButtonContainer>
            <div></div>
            <div>
              <ButtonWithWidth
                variant="outlined"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </ButtonWithWidth>
              <ButtonWithWidth
                disabled={!understandCheck}
                onClick={() => {
                  deleteProgram()
                  setShowDeleteModal(false)
                }}
              >
                Delete
              </ButtonWithWidth>
            </div>
          </ButtonContainer>
        </div>
      </Modal>
    </>
  )
}

export default ProgramDetails
