import React from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetEngagementsForProgram,
  useGetModules
} from 'src/shared/hooks/modules'
import { useProgram } from 'src/shared/hooks/program'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import ProgramOmni from '../views/Omni'

interface IParams {
  id: string
}
const ProgramOmniContainer = () => {
  /**
   * ----- Hook Initialization -----
   */

  const { id } = useParams<IParams>()

  const { isLoading, program } = useProgram(id)
  const { engagementsObject, isLoading: engagementsLoading } =
    useGetEngagementsForProgram(id)
  const { modules, isLoading: modulesLoading } = useGetModules()

  /**
   * ----- Variables -----
   */

  const filteredModules = React.useMemo(() => {
    if (engagementsObject === undefined) return []
    if (modules === undefined) return []
    return modules.filter((module) => {
      return engagementsObject.engagements.some((engagement) => {
        return engagement.modules.some(
          (engagementModule) => engagementModule.moduleId === module.moduleId
        )
      })
    })
  }, [engagementsObject, modules])

  /**
   * ----- Render -----
   */

  if (
    !isLoading &&
    !engagementsLoading &&
    !modulesLoading &&
    program &&
    engagementsObject !== undefined
  )
    return <ProgramOmni program={program} modules={filteredModules} />

  return <LoadingScreen />
}

export default ProgramOmniContainer
