import React, { FC, useEffect, useContext } from 'react'

import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { getCurrentUser, isUserAuthenticated } from 'src/api/services/amplify'
import { UserContext } from 'src/contexts/User'
import Claims from 'src/features/tools/containers/Claims'
import Dins from '../../features/tools/containers/Dins'

import {
  Activity,
  CreateProgram,
  Dashboard,
  DinInfo,
  Indications,
  Login,
  Program,
  ProgramDetails,
  Settings,
  EditLearningModule,
  CreateLearningModule,
  LearningModules,
  SideEffects,
  UserAdmin,
  UserInfo,
  UserProgram,
  Literacy,
  Playground
} from '../../pages'
import Verifications from 'src/features/tools/containers/Verification'
import BatchPayments from 'src/features/tools/containers/BatchPayments'
import CommercialCodes from 'src/features/tools/containers/CommercialCodes'
import UserVerifications from 'src/features/userAdmin/views/UserVerifications'
import WaitListContainer from 'src/features/program/containers/WaitList'
import ModulesContainer from 'src/features/modules/containers/Modules'
import ModuleContainer from 'src/features/modules/containers/Module'
import ModuleCreateContainer from 'src/features/modules/containers/Create'
import ProgramEngagementsContainer from 'src/features/program/containers/Engagements'
import ProgramOmniContainer from 'src/features/program/containers/Omni'
import ProgramConsentContainer from 'src/features/program/containers/Consent'

type RoutesProps = {
  path: string
  key: string
  exact?: boolean
  component: React.FC<any>
}

type RoutesPropsB = {
  path: string
  key: string
  exact?: boolean
  component: React.FC<any>
  routes?: RoutesProps[]
}

type AllRouteProps = {
  path: string
  key: string
  exact?: boolean
  component: React.FC<any>
  routes?: RoutesProps[]
}

type RenderRouteProps = {
  routes?: RoutesProps[]
}

// allows for rendering nested Route components
const RouteWithSubRoutes = (route: RoutesProps & RoutesPropsB) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  )
}

// Render root of router stuff
export const RenderRoutes: FC<RenderRouteProps> = ({ routes }) => {
  const history = useHistory()
  const { setUser } = useContext(UserContext)
  useEffect(() => {
    /**
     * Validates authentication, and kicks users out if they are not authenticated
     */
    const validateUserAuth = async () => {
      const isAuth = await isUserAuthenticated()
      if (!isAuth) {
        localStorage.removeItem('user')
        history.push('/login')
      } else {
        const currentUser = await getCurrentUser()
        setUser(currentUser.attributes)
      }
    }

    validateUserAuth()
    // eslint-disable-next-line
  }, [history])

  return (
    <Switch>
      {routes &&
        routes?.map((route, _i) => {
          return <RouteWithSubRoutes {...route} key={route.key} />
        })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  )
}

const developmentRoutes =
  process.env.NODE_ENV === 'development'
    ? [
        {
          path: '/playground',
          key: 'PLAYGROUND',
          exact: true,
          component: Playground
        }
      ]
    : []

const Routes: AllRouteProps[] = [
  { path: '/login', key: 'LOGIN', exact: true, component: Login },
  {
    path: '/',
    key: 'ROOT',
    component: (props) => {
      if (!localStorage.getItem('user')) {
        // alert('You need to log in to access app')
        return <Redirect to={'/login'} />
      } else if (props.location.pathname === '/') {
        return <Redirect to={'/programs'} />
      }
      return <RenderRoutes {...props} />
    },
    routes: [
      {
        path: '/programs',
        key: 'APP_ROOT',
        exact: true,
        component: () => <Dashboard />
      },
      {
        path: '/programs/:id/details',
        key: 'PROGRAM_DETAILS',
        exact: true,
        component: () => <ProgramDetails />
      },
      {
        path: '/programs/:id/wait-list',
        key: 'PROGRAM_WAIT_LIST',
        exact: true,
        component: () => <WaitListContainer />
      },
      {
        path: '/programs/:id/literacy',
        key: 'PROGRAM_LITERACY',
        exact: true,
        component: () => <Literacy />
      },
      {
        path: '/programs/:id/engagements',
        key: 'PROGRAM_ENGAGEMENTS',
        exact: true,
        component: () => <ProgramEngagementsContainer />
      },
      {
        path: '/programs/:id/omni',
        key: 'PROGRAM_OMNI',
        exact: true,
        component: () => <ProgramOmniContainer />
      },
      {
        path: '/programs/:id/consent',
        key: 'PROGRAM_CONSENT',
        exact: true,
        component: () => <ProgramConsentContainer />
      },
      {
        path: '/programs/create',
        key: 'CREATE_PROGRAM',
        exact: true,
        component: () => <CreateProgram />
      },
      {
        path: '/users',
        key: 'USER_ADMIN',
        exact: true,
        component: () => <UserAdmin />
      },
      {
        path: '/users/:id',
        key: 'USER_INFO',
        exact: true,
        component: () => <UserInfo />
      },
      {
        path: '/users/:id/info',
        key: 'USER_INFO',
        exact: true,
        component: () => <UserInfo />
      },
      {
        path: '/users/:id/programs',
        key: 'USER_PROGRAMS',
        exact: true,
        component: () => <Program />
      },
      {
        path: '/users/:id/verification',
        key: 'USER_VERIFICATION',
        exact: true,
        component: () => <UserVerifications />
      },
      {
        path: '/users/:id/activity',
        key: 'USER_ACTIVITY',
        exact: true,
        component: () => <Activity />
      },
      {
        path: '/users/:id/programs/:programId/prescription/:prescriptionId',
        key: 'USER_PROGRAM',
        exact: true,
        component: () => <UserProgram />
      },
      {
        path: '/settings',
        key: 'ADMIN_USER_SETTINGS',
        exact: true,
        component: () => <Settings />
      },
      {
        path: '/tools/batch-payments',
        key: 'TOOLS_BATCH_PAYMENTS',
        exact: true,
        component: () => <BatchPayments />
      },
      {
        path: '/tools/commercial-codes',
        key: 'TOOLS_COMMERCIAL_CODES',
        exact: true,
        component: () => <CommercialCodes />
      },
      {
        path: '/tools/claims',
        key: 'TOOLS_CLAIMS',
        exact: true,
        component: () => <Claims />
      },
      {
        path: '/tools/verification',
        key: 'TOOLS_VERIFICATION',
        exact: true,
        component: () => <Verifications />
      },
      {
        path: '/tools/dins',
        key: 'TOOLS_DINS',
        exact: true,
        component: () => <Dins />
      },
      {
        path: '/tools/dins/:din',
        key: 'TOOLS_DINS',
        exact: true,
        component: () => <DinInfo />
      },
      {
        path: '/learning/edit/:id',
        key: 'EDIT_LEARNING',
        exact: true,
        component: () => <EditLearningModule />
      },
      {
        path: '/learning/create',
        key: 'CREATE_LEARNING',
        exact: true,
        component: () => <CreateLearningModule />
      },
      {
        path: '/learning',
        key: 'LEARNING',
        exact: true,
        component: () => <LearningModules />
      },
      {
        path: '/tools/indications',
        key: 'TOOLS_INDICATIONS',
        exact: true,
        component: () => <Indications />
      },
      {
        path: '/tools/side-effects',
        key: 'TOOLS_SIDE_EFFECTS',
        exact: true,
        component: () => <SideEffects />
      },
      {
        path: '/modules',
        key: 'MODULES',
        exact: true,
        component: () => <ModulesContainer />
      },
      {
        path: '/module/create',
        key: 'CREATE_MODULE',
        exact: true,
        component: () => <ModuleCreateContainer />
      },
      {
        path: '/module/:id',
        key: 'MODULE',
        exact: true,
        component: () => <ModuleContainer />
      },
      ...developmentRoutes
    ]
  }
]

export default Routes
