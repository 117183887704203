import { TableCell, TableRow } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import React from 'react'
import { IProgramEntryCode } from 'src/models/program'
import {
  HoverableTableRow,
  StyledTable,
  TableHeaderCell
} from 'src/shared/styled/Table'
import { fuzzyFilter } from 'src/utils/table'

interface IEntryCodeTable {
  entryCodes: IProgramEntryCode[]
  selectEntryCode?: (entryCode: IProgramEntryCode) => void
  batch?: boolean
}

const EntryCodeTable = ({
  entryCodes,
  batch,
  selectEntryCode
}: IEntryCodeTable) => {
  /**
   * ----- Hook Initialization -----
   */

  /**
   * ----- Variables -----
   */

  const columns = React.useMemo<ColumnDef<IProgramEntryCode, any>[]>(() => {
    if (batch) {
      return [
        {
          header: 'Code',
          accessorKey: 'codeId'
        },
        {
          header: 'Single Use',
          accessorKey: 'singleUse',
          cell: ({
            row: {
              original: { singleUse }
            }
          }) => {
            if (singleUse === true) {
              return <Check style={{ color: 'green' }} />
            } else if (singleUse === false) {
              return <Close style={{ color: 'red' }} />
            } else {
              return 'Unknown'
            }
          }
        }
      ]
    }

    return [
      {
        header: 'Code',
        accessorKey: 'codeId'
      },
      {
        header: 'Description',
        accessorKey: 'description'
      },
      {
        header: 'Single Use',
        accessorKey: 'singleUse',
        cell: ({
          row: {
            original: { singleUse }
          }
        }) => {
          if (singleUse === true) {
            return <Check style={{ color: 'green' }} />
          } else if (singleUse === false) {
            return <Close style={{ color: 'red' }} />
          } else {
            return 'Unknown'
          }
        }
      },
      {
        header: 'Number of Uses',
        accessorKey: 'numUsages'
      }
    ]
  }, [batch])

  /**
   * ----- Render -----
   */

  const table = useReactTable({
    columns,
    data: entryCodes,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    initialState: {
      // sorting: [{ id: 'createdAt', desc: true }],
      pagination: {
        pageIndex: 0,
        pageSize: 25
      }
    },
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  return (
    <StyledTable>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeaderCell
                key={header.id}
                style={{ width: header.getSize() }}
              >
                <>
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </>
              </TableHeaderCell>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <HoverableTableRow
              key={row.id}
              onClick={() => selectEntryCode?.(row.original)}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                )
              })}
            </HoverableTableRow>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

export default EntryCodeTable
