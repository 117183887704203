import { useProgramBatchEntryCodeDetails } from 'src/shared/hooks/program'
import Modal from 'src/shared/views/Modal'
import EntryCodeTable from './EntryCodeTable'

interface IEntryCodeBatchDetails {
  programId: string
  batchName: string
  isOpen: boolean
  handleClose: () => void
}

const EntryCodeBatchDetails = ({
  programId,
  batchName,
  isOpen,
  handleClose
}: IEntryCodeBatchDetails) => {
  /**
   * ----- Hook Initialization -----
   */

  const { batchEntryCodeDetails } = useProgramBatchEntryCodeDetails(
    programId,
    batchName
  )

  /**
   * ----- Render -----
   */

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title={batchName}>
      <EntryCodeTable
        entryCodes={batchEntryCodeDetails?.entryCodes || []}
        batch
      />
    </Modal>
  )
}

export default EntryCodeBatchDetails
