import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import styled from 'styled-components'

import TextField from 'src/shared/views/TextField'
import { FONTS } from 'src/shared/theme'
import { IProgramUpdateData } from 'src/models/program'
import { Control, Controller } from 'react-hook-form'
import LanguageMapForm from 'src/shared/views/LanguageMapForm'
import { SupportedLanguages } from 'src/models/misc'
import SupportedLanguageForm from 'src/shared/views/SupporedLanguageForm'

const StyledTypography = styled(Typography)`
  font-family: ${FONTS.main};
  color: ${(props) => props.theme.palette.common.black};
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 19px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-self: flex-start;
`

const StyledTextField = styled(TextField)`
  margin-right: 40px;
`

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  & label:first-of-type {
    margin-bottom: 20px;
  }
`

const ProgramInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
`

interface IProgramInfo {
  control: Control<IProgramUpdateData, any>
  canCurrentUserWrite?: boolean
  supportedLanguages: SupportedLanguages
}

const ProgramInfo = ({
  canCurrentUserWrite,
  control,
  supportedLanguages
}: IProgramInfo) => {
  /**
   * ----- Render -----
   */

  return (
    <>
      <ProgramInfoContainer>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <StyledTextField
              label="Program Name"
              disabled={!canCurrentUserWrite}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="commonNameTranslations"
          render={({ field: { value, onChange } }) => (
            <LanguageMapForm
              label="Common Name"
              supportedLanguages={supportedLanguages}
              textFieldProps={{
                disabled: !canCurrentUserWrite
              }}
              value={value || {}}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="funds.amount"
          control={control}
          render={({ field }) => (
            <StyledTextField
              label="Funds ($)"
              type="number"
              disabled={!canCurrentUserWrite}
              InputProps={{ inputProps: { min: 0 } }}
              {...field}
            />
          )}
        />
        <ToggleContainer>
          <Controller
            name="waitListEnabled"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...field}
                  />
                }
                label={<StyledTypography>Enable Waitlist</StyledTypography>}
              />
            )}
          />
        </ToggleContainer>
        <Controller
          name="supportedLanguages"
          control={control}
          render={({ field: { value, onChange } }) => (
            <SupportedLanguageForm
              value={value || []}
              onChange={onChange}
              englishDisabled
            />
          )}
        />
      </ProgramInfoContainer>
    </>
  )
}

export default ProgramInfo
