import { yupResolver } from '@hookform/resolvers/yup'
import { useFieldArray, useForm } from 'react-hook-form'
import { LanguageMap } from 'src/models/misc'
import { IProgramCreateData, IProgramUpdateData } from 'src/models/program'
import * as yup from 'yup'

const ModuleSchema = yup.object().shape({
  module: yup.string().required('Module is required')
})

const useModuleForm = (options?: any) => {
  return useForm({
    resolver: yupResolver(ModuleSchema),
    ...options
  })
}

const EntryPointSchema = yup.object().shape({
  displayLabel: yup
    .string()
    .min(4, 'Must be at least 4 characters')
    .max(30, 'Must be under 30 characters')
    .required('Must provide a display label'),
  visibleToPatient: yup.bool().required(),
  description: yup.string().required('Must provide a description'),
  promptForCode: yup.bool().default(false).required(),
  consentEnabled: yup.bool().default(false).required(),
  imageUrl: yup.string().required(),
  screeningQuestions: yup.object().shape({
    questions: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        content: yup.object().shape({
          en: yup.string(),
          fr: yup.string()
        })
      })
    )
  })
})

type IEntryPointForm = {
  displayLabel: string
  visibleToPatient: boolean
  consentEnabled: boolean
  description: string
  promptForCode: boolean
  imageUrl: string
  screeningQuestions: {
    questions: {
      name: string
      content: LanguageMap
    }[]
  }
}

const useEntryPointForm = (options?: any) => {
  const form = useForm<IEntryPointForm>({
    resolver: yupResolver(EntryPointSchema),
    defaultValues: {
      visibleToPatient: false,
      promptForCode: false,
      screeningQuestions: { questions: [] },
      ...options?.defaultValues
    },
    ...options
  })

  // Initialize the field array for screening questions
  const screeningQuestionsArray = useFieldArray({
    control: form.control,
    name: 'screeningQuestions.questions'
  })

  const addScreeningQuestion = () => {
    screeningQuestionsArray.append({
      name: '',
      content: { en: '', fr: '' }
    })
  }

  const removeScreeningQuestion = (index: number) => {
    screeningQuestionsArray.remove(index)
  }

  return {
    ...form,
    addScreeningQuestion,
    removeScreeningQuestion,
    screeningQuestionsArray
  }
}

const ProgramCreateSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  commonNameTranslations: yup.object().shape({
    en: yup.string(),
    fr: yup.string()
  }),
  funds: yup.object().shape({
    amount: yup.number().required('Funds amount is required'),
    currencyCode: yup.string().required('Currency code is required')
  }),
  dins: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('DIN value is required')
      })
    )
    .required('DINs are required')
    .min(1, 'At least one DIN is required'),
  endDate: yup.date().required('End date is required'),
  waitListEnabled: yup.bool().required('Waitlist is required'),
  entryPoint: EntryPointSchema,
  supportedLanguages: yup.array().of(yup.string())
})

const useProgramCreateForm = (options?: any) => {
  return useForm<IProgramCreateData>({
    resolver: yupResolver(ProgramCreateSchema),
    defaultValues: {
      name: '',
      commonName: '',
      dins: [],
      endDate: new Date(),
      funds: {
        amount: 100,
        currencyCode: 'CAD'
      },
      waitListEnabled: false,
      entryPoint: {
        displayLabel: '',
        description: '',
        imageUrl: '',
        promptForCode: true,
        visibleToPatient: false,
        screeningQuestions: {
          questions: []
        }
      },
      supportedLanguages: ['en']
    },
    ...options
  })
}

const ProgramUpdateSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  commonNameTranslations: yup.object().shape({
    en: yup.string(),
    fr: yup.string()
  }),
  funds: yup.object().shape({
    amount: yup.number().required('Funds amount is required'),
    currencyCode: yup.string().required('Currency code is required')
  }),
  waitListEnabled: yup.bool().required('Waitlist is required'),
  supportedLanguages: yup.array().of(yup.string())
})

const useProgramUpdateForm = (options?: any) => {
  return useForm<IProgramUpdateData>({
    resolver: yupResolver(ProgramUpdateSchema),
    ...options
  })
}

export {
  useModuleForm,
  useEntryPointForm,
  useProgramCreateForm,
  useProgramUpdateForm
}
