import { useParams } from 'react-router-dom'
import { useGetEngagementsForProgram } from 'src/shared/hooks/modules'
import { useProgram } from 'src/shared/hooks/program'
import LoadingScreen from 'src/shared/views/LoadingScreen'
import Engagements from '../views/Engagements'

interface IParams {
  id: string
}
const ProgramEngagementsContainer = () => {
  const { id } = useParams<IParams>()

  const { isLoading, program } = useProgram(id)
  const {
    engagementsObject,
    isLoading: engagementsLoading,
    refetch
  } = useGetEngagementsForProgram(id)

  if (
    !isLoading &&
    !engagementsLoading &&
    program &&
    engagementsObject !== undefined
  )
    return (
      <Engagements
        program={program}
        engagements={engagementsObject.engagements}
        version={engagementsObject.version}
        refetchEngagements={refetch}
      />
    )

  return <LoadingScreen />
}

export default ProgramEngagementsContainer
