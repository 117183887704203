import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  Switch,
  Typography
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { downloadImage, uploadImage } from 'src/api/services/amplify'
import { FETCH_PROGRAM } from 'src/constants/queries'
import { IEntryPointData, IProgram } from 'src/models/program'
import {
  useCreateProgramEntryPoint,
  useDeleteProgramEntryPoint,
  useGetProgramConsent,
  useUpdateProgramEntryPoint
} from 'src/shared/hooks/program'
import { ButtonWithMargin } from 'src/shared/styled/Buttons'
import { FONTS } from 'src/shared/theme'
import { ImageUploader } from 'src/shared/views/ImageUploader'
import LanguageMapForm from 'src/shared/views/LanguageMapForm'
import ThemedTextField from 'src/shared/views/TextField'
import Tooltip from 'src/shared/views/Tooltip'
import styled from 'styled-components'
import { useEntryPointForm } from '../hooks/forms'

interface IProgramEntryPoint {
  program: IProgram
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(203, 213, 224);
  padding: 20px;
  border-radius: 5px;
`

const EditButton = styled(ButtonWithMargin)`
  max-height: 35px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  align-self: flex-start;
`

const StyledTypography = styled(Typography)`
  font-family: ${FONTS.main};
  color: ${(props) => props.theme.palette.common.black};
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
  line-height: 19px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: end;
  width: 100%;
  margin-top: 2em;
`

const ProgramEntryPoint: React.FC<IProgramEntryPoint> = ({ program }) => {
  /**
   * ----- Hook Initialization -----
   */

  const [imageToDisplay, setImageToDisplay] = React.useState<any>('')

  const queryClient = useQueryClient()

  const {
    register,
    setError,
    clearErrors,
    setValue,
    watch,
    handleSubmit,
    addScreeningQuestion,
    removeScreeningQuestion,
    screeningQuestionsArray,
    control,
    formState: { errors, isDirty }
  } = useEntryPointForm({
    defaultValues: {
      ...program.entryPoint
    }
  })

  const { updateEntryPoint, isLoading: updateLoading } =
    useUpdateProgramEntryPoint(program.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(FETCH_PROGRAM(program.id))
        toast.success('Entry point updated successfully')
      }
    })

  const { createEntryPoint, isLoading: createLoading } =
    useCreateProgramEntryPoint(program.id, {
      onSuccess: () => {
        toast.success('Entry point created successfully')
      }
    })

  const { deleteEntryPoint } = useDeleteProgramEntryPoint(program.id, {
    onSuccess: () => {
      toast.success('Entry point deleted successfully')
    }
  })

  const { consent } = useGetProgramConsent(program.id)

  /**
   * ----- Variables -----
   */

  const imageUrl = watch('imageUrl')

  const loading = updateLoading || createLoading

  /**
   * ----- Functions -----
   */

  const submitHandler = React.useCallback(
    (data: IEntryPointData) => {
      if (program.entryPoint) {
        updateEntryPoint(data)
      } else {
        createEntryPoint(data)
      }
    },
    [program.entryPoint, updateEntryPoint, createEntryPoint]
  )

  const onHandleImageChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const imageFile = e?.target?.files && e?.target?.files[0]

      if (!imageFile) return

      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(imageFile.type)) {
        setError('imageUrl', { message: 'File must be a png or jpeg' })
      } else {
        clearErrors('imageUrl')
        const img = URL.createObjectURL(imageFile)
        const remoteImage = await uploadImage(img, 'access-code')
        setValue('imageUrl', remoteImage || '', { shouldDirty: true })
      }
    },
    [clearErrors, setError, setValue]
  )

  const onClearImage = React.useCallback(() => {
    setValue('imageUrl', '', { shouldDirty: true })
  }, [setValue])

  /**
   * ----- UseEffect -----
   */

  React.useEffect(() => {
    const getImage = async () => {
      if (imageUrl) {
        const remoteImageUrl = await downloadImage(imageUrl)
        setImageToDisplay(remoteImageUrl)
      } else {
        setImageToDisplay('')
      }
    }

    getImage()
  }, [imageUrl])

  /**
   * ----- Rendering -----
   */

  return (
    <FormContainer onSubmit={handleSubmit(submitHandler)}>
      <Row>
        <Controller
          control={control}
          name="displayLabel"
          render={({ field: { ref, ...props } }) => (
            <ThemedTextField
              style={{ width: '50%' }}
              label="Display Label"
              inputRef={ref}
              helperText={errors?.displayLabel?.message}
              error={!!errors?.displayLabel?.message}
              {...props}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { ref, ...props } }) => (
            <ThemedTextField
              style={{ width: '50%' }}
              label="Description"
              inputRef={ref}
              helperText={errors?.description?.message}
              error={!!errors?.description?.message}
              multiline
              {...props}
            />
          )}
        />
      </Row>
      <Row>
        <Column>
          <Controller
            control={control}
            name="imageUrl"
            render={({ field: { ...props }, fieldState: { error } }) => (
              <ImageUploader
                id="imageUrl"
                label="Upload Image"
                error={error?.message}
                onHandleImageChange={onHandleImageChange}
                onClearImage={onClearImage}
                imagePath={imageToDisplay}
                width={300}
                height={170}
                {...props}
              />
            )}
          />
        </Column>
        <Column>
          <Controller
            name="visibleToPatient"
            control={control}
            render={({ field: { onChange, value, ...props } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...props}
                  />
                }
                label={<StyledTypography>Visible</StyledTypography>}
              />
            )}
          />
          <Controller
            name="consentEnabled"
            control={control}
            render={({ field: { onChange, value, ...props } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    disabled={!consent}
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...props}
                  />
                }
                label={
                  !consent ? (
                    <Tooltip description="Consent content must be created first">
                      <StyledTypography>Enable Consent</StyledTypography>
                    </Tooltip>
                  ) : (
                    <StyledTypography>Enable Consent</StyledTypography>
                  )
                }
              />
            )}
          />
          <Controller
            name="promptForCode"
            control={control}
            render={({ field: { onChange, value, ...props } }) => (
              <StyledFormControlLabel
                control={
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    {...props}
                  />
                }
                label={<StyledTypography>Code Required</StyledTypography>}
              />
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Row>
            <h3>Screening Questions</h3>
            <ButtonWithMargin onClick={() => addScreeningQuestion()}>
              Add
            </ButtonWithMargin>
          </Row>
          {screeningQuestionsArray.fields.map((field, index) => (
            <Card key={field.id}>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2
                  }}
                >
                  <Typography variant="subtitle1">
                    Question {index + 1}
                  </Typography>
                  <IconButton
                    onClick={() => removeScreeningQuestion(index)}
                    size="small"
                  >
                    <Delete />
                  </IconButton>
                </Box>

                <ThemedTextField
                  label="Question Name"
                  fullWidth
                  {...register(`screeningQuestions.questions.${index}.name`)}
                  error={!!errors.screeningQuestions?.questions?.[index]?.name}
                  helperText={
                    errors.screeningQuestions?.questions?.[index]?.name?.message
                  }
                />

                <Typography variant="subtitle2">Question Content</Typography>

                <LanguageMapForm
                  value={watch(`screeningQuestions.questions.${index}.content`)}
                  onChange={(value) => {
                    // Update the form value directly using setValue
                    setValue(
                      `screeningQuestions.questions.${index}.content`,
                      value,
                      { shouldDirty: true }
                    )
                  }}
                  supportedLanguages={program.supportedLanguages}
                  textFieldProps={{
                    multiline: true,
                    rows: 2
                  }}
                />
              </CardContent>
            </Card>
          ))}
        </Column>
      </Row>
      <Row>
        <ButtonContainer>
          <EditButton startIcon={<Delete />} onClick={() => deleteEntryPoint()}>
            Delete
          </EditButton>
          <EditButton disabled={!isDirty} type="submit" isLoading={loading}>
            Save
          </EditButton>
        </ButtonContainer>
      </Row>
    </FormContainer>
  )
}

export default ProgramEntryPoint
